import { apiGet_demo_accs } from '@/resource'

export default {
  data() {
    return {
      userInfo: {
        platform: '4',
      },
    }
  },
  computed: {
    urls() {
      return this.$config.getMetaTraderLinks(this.regulator, this.GLOBAL_DOMAIN_WEBSITE)
    },
  },
  mounted() {
    apiGet_demo_accs().then(resp => {
      if (resp.data.code == 0 && resp.data.data.length > 0) {
        this.userInfo = { ...resp.data.data[0] }
      }
    })
  },
}
