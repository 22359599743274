<template>
  <div id="demo">
    <div class="content-box">
      <div class="inner">
        <!-- <div class="demo-box">
          <div class="demo-main">
            <div class="title">
              <div class="text big">
                <b>{{ $t('register.demo.congrad') }}</b>
              </div>
              <div class="text small">{{ $t('register.demo.title', { platform: userInfo.platform }) }}</div>
            </div>
            <div class="top">
              <div class="detail">
                <h3>{{ $t('register.demo.title2') }}</h3>
                <ul>
                  <li>
                    {{ $t('register.demo.li.login') }}
                    <b>{{ userInfo.accNum }}</b>
                  </li>
                  <li>
                    {{ $t('register.demo.li.srv') }}
                    <b>{{ userInfo.server }}</b>
                  </li>
                </ul>
                <img src="@/assets/images/register/demo.png" alt />
                <span>{{ $t('register.demo.emailSent') }}</span>
              </div>
              <div class="download">
                <h3>{{ $t('register.demo.dl', { platform: userInfo.platform }) }}</h3>
                <ul v-if="urls[`mt${userInfo.platform}`]">
                  <li>
                    <div class="title">{{ $t('downloads.windows') }}</div>
                    <a
                      :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_windows`]"
                      data-testid="_windows"
                    >
                      <div class="clickable">
                        <div class="picture windows"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">Mac OS</div>
                    <a :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_iOS`]" data-testid="_Mac">
                      <div class="clickable">
                        <div class="picture mac"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">Linux</div>
                    <a
                      :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_windows`]"
                      data-testid="_linux"
                    >
                      <div class="clickable">
                        <div class="picture linux"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.ip') }}</div>
                    <a
                      :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_iPhone`]"
                      data-testid="_iPhone"
                    >
                      <div class="clickable">
                        <div class="picture iphone"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.android') }}</div>
                    <a
                      :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_Android`]"
                      data-testid="_Android"
                    >
                      <div class="clickable">
                        <div class="picture android"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.web') }}</div>
                    <a
                      :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_WebTrader`]"
                      data-testid="_WebTrader"
                    >
                      <div class="clickable">
                        <div class="picture webtrader"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="bottom">
              <h3>{{ $t('register.demo.improveQ') }}</h3>
              <div class="text">{{ $t('register.demo.improveWay') }}</div>
              <div class="boxs">
                <div>
                  <div class="title">{{ $t('register.demo.proTrade') }}</div>
                  <img src="@/assets/images/register/demo01.png" alt />
                </div>
                <div>
                  <div class="title">{{ $t('register.demo.customerService') }}</div>
                  <img src="@/assets/images/register/demo02.png" alt />
                </div>
                <div>
                  <div class="title">{{ $t('register.demo.competitivePromotionals') }}</div>
                  <img src="@/assets/images/register/demo03.png" alt />
                </div>
                <div>
                  <div class="title">{{ $t('register.demo.secure') }}</div>
                  <img src="@/assets/images/register/demo04.png" alt />
                </div>
              </div>

              <div class="btn-box">
                <router-link :to="{ name: 'register' }" class="el-button btn-red">
                  {{ $t('register.demo.openLiveAcc') }}
                </router-link>
              </div>
            </div>
          </div>
        </div> -->
        <div class="demo-container">
          <div class="demo-wrapper">
            <div class="demo-head">
              <h1 class="demo-head-title">{{ $t('register.demo.congrad') }}</h1>
              <h2 class="demo-head-title sub">{{ $t('register.demo.title', { platform: userInfo.platform }) }}</h2>
            </div>

            <div class="demo-content">
              <div class="demo-content-title">{{ $t('register.demo.title2') }}</div>

              <div class="demo-table">
                <div class="demo-row">
                  <div class="row-item">
                    <div>{{ $t('register.demo.li.login') }}</div>
                  </div>
                  <div class="row-item">
                    <div>{{ $t('register.demo.li.srv') }}</div>
                  </div>
                </div>
                <div class="demo-row body">
                  <div class="row-item">
                    <div>{{ userInfo.accNum }}</div>
                  </div>
                  <div class="row-item">
                    <div>{{ userInfo.server }}</div>
                  </div>
                </div>
              </div>

              <div class="message">
                <img src="@/assets/images/label_icon_blue.png" alt="alert-icon" />
                <span class="message-text">{{ $t('register.demo.emailSent') }}</span>
              </div>
            </div>
          </div>
          <div class="demo-footer">
            <h4 class="demo-footer-title">{{ $t('register.demo.dl', { platform: userInfo.platform }) }}</h4>
            <div class="dowload-area">
              <div class="download-item" v-for="(item, index) in downloadItems" :key="index">
                <a :href="item.url" :data-testid="item.dataTestid">
                  <img :src="item.src" :alt="item.name" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import demoMixins from '@/mixins/register/demo';

export default {
  name: 'demo_default',
  mixins: [demoMixins],
  computed: {
    downloadItems() {
      return [
        {
          name: 'windowDownload',
          src: require('@/assets/images/download/windows-icon.png'),
          dataTestid: '_windows',
          url: `${this.urls[`mt${this.userInfo.platform}`][`METATRADER${this.userInfo.platform}_windows`]}`
        },
        {
          name: 'masOSDownload',
          src: require('@/assets/images/download/masOS-icon.png'),
          dataTestid: '_Mac',
          url: `${this.urls[`mt${this.userInfo.platform}`][`METATRADER${this.userInfo.platform}_iOS`]}`
        },
        {
          name: 'linuxDownload',
          src: require('@/assets/images/download/linux-icon.png'),
          dataTestid: '_linux',
          url: `${this.urls[`mt${this.userInfo.platform}`][`METATRADER${this.userInfo.platform}_windows`]}`
        },
        {
          name: 'iphoneDownload',
          src: require('@/assets/images/download/iphone-icon.png'),
          dataTestid: '_iPhone',
          url: `${this.urls[`mt${this.userInfo.platform}`][`METATRADER${this.userInfo.platform}_iPhone`]}`
        },
        {
          name: 'androidDownload',
          src: require('@/assets/images/download/android-icon.png'),
          dataTestid: '_Android',
          url: `${this.urls[`mt${this.userInfo.platform}`][`METATRADER${this.userInfo.platform}_Android`]}`
        },
        {
          name: 'webTraderDownload',
          src: require('@/assets/images/download/webTrader-icon.png'),
          dataTestid: '_WebTrader',
          url: `${this.urls[`mt${this.userInfo.platform}`][`METATRADER${this.userInfo.platform}_WebTrader`]}`
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/demo.scss';
</style>
